<template>
    <b-container>
        <b-row style="
        display: flex;
        flex-wrap: wrap;
        margin-right: -9px;
        margin-left: -12px;"
        >
            <b-col lg="12" sm="12">
                <form-input :load="load" :key="reloadComponent" :data="formInputData" @change="setData" @submit="register" />
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import RestApi, { agriMarketingServiceBaseUrl } from '@/config/api_config'
import { paStore, growerList, paUpdate } from '../../api/routes'
import FormInput from '@/components/structure/form/validationForm/FormInput'
import { Common, Repository, helpers } from '@/mixins/helper-functions'
import CommonFunc from '@/Utils/common'

export default {
    props: ['item'],
    mixin: [Common],
    components: {
        FormInput
    },
    created () {
        if (this.item !== null) {
            const tmp = JSON.parse(JSON.stringify(this.item))
            Object.assign(this.parentObject, tmp)
        }
        if (this.isOfficeUser) {
          const authOfficeDetail = this.$store.state.Auth.authUser.office_detail
          const unit = this.$store.state.agriMarketing.commonObj.unitList.find(obj => obj.district_id === authOfficeDetail.district_id)
          if (unit !== undefined) {
            this.parentObject.region_id = unit.region_id
            this.parentObject.zone_id = unit.zone_id
            this.parentObject.unit_id = unit.value
            this.parentObject.district_id = unit.district_id
            this.parentObject.upazilla_id = unit.upazilla_id
          }
        }
    },
    data () {
        return {
            isFinal: false,
            parentObject: {
                applicant_id: '',
                region_id: '',
                zone_id: '',
                unit_id: '',
                grower_id: '',
                cotton_variety_id: '',
                cotton_id: '',
                other_cotton_variety_en: '',
                other_cotton_variety_bn: '',
                type: 2
            },
            growerList: [],
            zoneList: [],
            unitList: [],
            growerListLoading: false,
            item_id: '',
            passType: 'password',
            load: false,
            reloadComponent: 1,
            cottonNameList: []
        }
    },
    computed: {
        districtList () {
           return this.$store.state.commonObj.districtList.filter(item => item.status === 0)
        },
        upazilaList () {
          return this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
        },
        regionList () {
            return this.$store.state.agriMarketing.commonObj.regionList.filter(item => item.status === 1)
        },
        fiscalYearList () {
            // const fiscalyearData = this.$store.state.commonObj.fiscalYearList
            // const currentFiscalYear = fiscalyearData.find(item => item.value === this.$store.state.currentFiscalYearId)
            // const maxSortingOrder = currentFiscalYear.sorting_order
            // const minSortingOrder = maxSortingOrder - 2
            // return fiscalyearData.filter(item => item.sorting_order >= minSortingOrder && item.sorting_order <= maxSortingOrder)
            //                     .sort((a, b) => b.soting_order - a.sorting_order)
            return CommonFunc.getFiscalYearList()
        },
        cottonVarietyList () {
            return this.$store.state.agriMarketing.commonObj.cottonVaritiesList.filter(item => item.status === 1)
        },
        seasonList () {
            return this.$store.state.agriMarketing.commonObj.seasonList.filter(item => item.status === 1)
        },
        isEn () {
          return this.$i18n.locale === 'en'
        },
        formInputData () {
            return {
                chunk: true,
                buttonLocale: this.item_id ? 'globalTrans.update' : 'globalTrans.save',
                buttonClass: 'btn btn-primary btn-block',
                dataParent: this.parentObject,
                data: [
                    {
                        head: {
                            title: ''
                        },
                        items: [
                            /* {
                                name: 'type',
                                type: 'Select',
                                label: 'cotton_ginner_grower.selectGrowerGinner',
                                options: helpers.filterListLang(Custom.ginnerGrower),
                                labelCol: 4
                            }, */
                            {
                                name: 'fiscal_year_id',
                                type: 'Select',
                                label: 'sidebar.warehouseSubNav.fiscal_year',
                                options: this.fiscalYearList,
                                labelCol: 4
                            },
                            {
                                name: 'achievement_date',
                                type: 'Datepicker',
                                label: 'globalTrans.date',
                                labelCol: 4
                            },
                            /* {
                                name: 'applicant_id',
                                type: 'Input',
                                label: 'cotton_ginner_grower.applicantId',
                                labelCol: 4
                            }, */
                            {
                                name: 'region_id',
                                type: 'Select',
                                label: 'globalTrans.region',
                                options: this.regionList,
                                labelCol: 4,
                                readOnly: this.isOfficeUser
                            },
                            {
                                name: 'zone_id',
                                type: 'Select',
                                label: 'cotton_config.zone_name',
                                options: helpers.filterListLang(this.zoneList),
                                labelCol: 4,
                                readOnly: this.isOfficeUser
                            },
                            {
                                name: 'unit_id',
                                type: 'Select',
                                label: 'cotton_config.unit_name',
                                options: helpers.filterListLang(this.unitList, 'text', 'text_bn', 'value'),
                                placeholder: this.growerListLoading ? 'Loading..' : '',
                                readOnly: this.growerListLoading || this.isOfficeUser,
                                labelCol: 4
                            },
                            {},
                            {
                                name: 'grower_id',
                                type: 'Select',
                                label: 'cotton_ginner_grower.grower',
                                options: this.growerList,
                                placeholder: this.growerListLoading ? 'Loading..' : '',
                                readOnly: this.growerListLoading,
                                labelCol: 4
                            },
                            {
                                name: 'district_id',
                                type: 'Select',
                                label: 'globalTrans.district',
                                options: this.districtList,
                                labelCol: 4,
                                readOnly: true
                            },
                            {
                                name: 'upazilla_id',
                                type: 'Select',
                                label: 'globalTrans.upazila',
                                options: this.upazilaList,
                                labelCol: 4,
                                readOnly: true
                            },
                            {
                                name: 'father_name',
                                type: 'Input',
                                label: 'fertilizerConfig.father_name_en',
                                labelCol: 4,
                                readOnly: true
                            },
                            {
                                name: 'father_name_bn',
                                type: 'Input',
                                label: 'warehouseFarmer.father_name_bn',
                                labelCol: 4,
                                readOnly: true
                            },
                            {
                                name: 'address',
                                type: 'Input',
                                label: 'irrigation_config.address',
                                labelCol: 4,
                                readOnly: true
                            },
                            {
                                name: 'address_bn',
                                type: 'Input',
                                label: 'irrigation_config.address_bn',
                                labelCol: 4,
                                readOnly: true
                            },
                            {
                                name: 'mobile_no',
                                type: 'Input',
                                label: 'globalUserData.mobile_no',
                                labelCol: 4,
                                readOnly: true
                            },
                            {
                                name: 'cotton_variety_id',
                                type: 'Select',
                                label: 'cotton_ginner_grower.cottonVariety',
                                options: helpers.filterListLang(this.cottonVarietyList),
                                labelCol: 4
                            },
                            {
                                name: 'cotton_id',
                                type: 'Select',
                                label: 'cotton_ginner_grower.cottonName',
                                options: helpers.filterListLang(this.cottonNameList),
                                labelCol: 4
                            },
                            {
                              name: 'other_cotton_variety_en',
                              type: 'Input',
                              label: 'cotton_ginner_grower.other_cotton_variety_en',
                              labelCol: 4,
                              onIf: this.parentObject.cotton_id === -1
                            },
                            {
                              name: 'other_cotton_variety_bn',
                              type: 'Input',
                              label: 'cotton_ginner_grower.other_cotton_variety_bn',
                              labelCol: 4,
                              onIf: this.parentObject.cotton_id === -1
                            },
                            {
                                name: 'seasons_id',
                                type: 'Select',
                                label: 'cotton_ginner_grower.season',
                                options: helpers.filterListLang(this.seasonList),
                                labelCol: 4
                            },
                            {
                                name: 'quantity',
                                type: 'Input',
                                label: 'cotton_ginner_grower.quantity',
                                labelCol: 4
                            },
                            {
                                name: 'remarks',
                                type: 'Textarea',
                                label: 'cotton_config.remarks_en',
                                labelCol: 4,
                                rules: 'nullable'
                            },
                            {
                                name: 'remarks_bn',
                                type: 'Textarea',
                                label: 'cotton_config.remarks_bn',
                                labelCol: 4,
                                rules: 'nullable'
                            }
                        ]
                    }
                ]
            }
        },
        isOfficeUser () {
        //   return !(this.$store.state.Auth.activeRoleId === 1 || this.$store.state.Auth.authUser.is_org_admin)
          return false
        },
        currentLocale () {
          return this.$i18n.locale
        }
    },
    watch: {
        'parentObject.region_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.zoneList = this.setZone(newVal)
            }
        },
        'parentObject.zone_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.unitList = this.setUnit(newVal)
            }
        },
        'parentObject.unit_id': async function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.growerList = await this.setGrower(newVal)
            }
        },
        'parentObject.cotton_variety_id': function (newVal, oldVal) {
            if (oldVal !== newVal) {
                this.cottonNameList = this.getCottonNameList(newVal)
                // this.cottonNameList.push({
                //   text_en: this.$t('cotton_ginner_grower.others', 'en'),
                //   text_bn: this.$t('cotton_ginner_grower.others', 'bn'),
                //   text: this.currentLocale === 'en' ? this.$t('cotton_ginner_grower.others', 'en') : this.$t('cotton_ginner_grower.others', 'bn'),
                //   value: -1
                // })
            }
        },
        'parentObject.cotton_id': function (newVal, oldVal) {
          this.reloadComponent++
        },
        'parentObject.grower_id': async function (newVal, oldVal) {
            if (newVal !== oldVal) {
                // const grower = this.$store.state.agriMarketingCottonGinnerGrower.growerDropdownList.find(el => el.applicant_id === newVal)
                const grower = this.growerList.find(el => el.applicant_id === newVal)
                if (grower) {
                    Object.assign(this.parentObject, grower)
                }
                this.reloadComponent++
            }
        }
    },
    methods: {
        setZone (orgId) {
            return this.$store.state.agriMarketing.commonObj.zoneList.filter(item => item.status === 1).filter(el => el.region_id === orgId)
        },
        setUnit (zoneId) {
            return this.$store.state.agriMarketing.commonObj.unitList.filter(item => item.status === 1).filter(el => el.zone_id === zoneId)
        },
        async setGrower (unitId) {
            this.growerListLoading = true
            let list = []
            const result = await RestApi.getData(agriMarketingServiceBaseUrl, growerList, { unit_id: unitId, type: 2 })
            if (result.success) {
                const data = result.data
                if (data.length > 0) {
                    this.$store.dispatch('agriMarketingCottonGinnerGrower/setGrowerDropdownList', data)
                    list = data.map(el => {
                        el.text = this.isEn ? el.name : el.name_bn
                        el.value = el.applicant_id
                        return el
                    })
                }
                this.growerListLoading = false
            } else {
                this.growerListLoading = false
            }
            return list
        },
        setData (data) {
            this.parentObject = Object.assign(this.parentObject, data)
        },
        getData () {
            return this.$store.state.parentObject
        },
        async register () {
            this.load = true
            delete this.parentObject.status
            await Repository.create({
                data: this.parentObject,
                baseURL: agriMarketingServiceBaseUrl,
                storeAPI: paStore,
                updateAPI: paUpdate,
                itemId: this.parentObject.id
            })
            this.load = false
            this.$emit('submitted')
        },
        getCottonNameList (cottonVarietyId) {
            return this.$store.state.agriMarketing.commonObj.cottonNameList.filter(item => (item.status === 1) && (item.cotton_variety_id === cottonVarietyId))
        }
    }
}
</script>
